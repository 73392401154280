import React from "react";
import { Route, Routes } from "react-router-dom";
import ExtractExpertsLanding from "./landing_search/extract_experts_landing.jsx"
import NavBar from "./navbar/navbar.jsx"
import Footer from "./footer/footer.jsx"
import Careers from "./footer/company-details/careers.jsx";
import { AboutUs } from "./footer/about-us/AboutUs.jsx";
import ContactUs from "./footer/contact-us/ContactUs.jsx";
import CorePlatform from "./core_platform/CorePlatform.jsx";
import PrivacyPolicy from "./footer/privacy-policy/PrivacyPolicy.jsx";

// react-router-dom v6 uses Routes instead of switch and matches exact path by default
const App = () => {
    return (
        <div>
            <NavBar />
            <div>
                <Routes>
                    <Route path="/" element={<ExtractExpertsLanding />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/core-platform" element={<CorePlatform />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
