import React from 'react';

const Careers = () => {
    return (
        <section>
            <h2 className='generic-header'>
                Careers
            </h2>
            <div>
                At Extract Experts, we are pioneers in the web-scraping industry, known for our innovative and collaborative approach. As a remote-only company, we offer the flexibility to work from anywhere in the world, ensuring a perfect work-life balance. Our flat organizational structure means that every team member’s input is valued, fostering a supportive environment where engineering expertise is at the forefront.
                <br /><br />

                We are looking for driven individuals who thrive in dynamic environments and are passionate about web-scraping. At Extract Experts, you will have the opportunity to learn, grow, and make a tangible impact. Join us and be part of a team that values creativity, autonomy, and excellence.
                <br /><br />
                Explore our career opportunities and start your journey with us today.
            </div>
            <br /><br />

            <h3>No open positions right now, check back soon!</h3>
        </section>
    )
}


export default Careers;
