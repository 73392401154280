// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: black;
  color: rgb(240, 234, 234);
  font-size: 24px;
  font-family: 'Arial Rounded MT Bold';
  font-size: 22px;
  padding: 2%;
  padding-top: 0%;
}

a {
  text-decoration: none;
  color: inherit;
}

.general-link-text {
  color: rgb(27, 145, 184);
}

.general-link-text:hover {
  color: rgb(20, 122, 157);
}

.generic-header {
  border-radius: 5%;
  border-style: groove;
  border-color: rgb(47, 139, 170);
  border-width: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,eAAe;EACf,oCAAoC;EACpC,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,+BAA+B;EAC/B,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["body {\n  background-color: black;\n  color: rgb(240, 234, 234);\n  font-size: 24px;\n  font-family: 'Arial Rounded MT Bold';\n  font-size: 22px;\n  padding: 2%;\n  padding-top: 0%;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n.general-link-text {\n  color: rgb(27, 145, 184);\n}\n\n.general-link-text:hover {\n  color: rgb(20, 122, 157);\n}\n\n.generic-header {\n  border-radius: 5%;\n  border-style: groove;\n  border-color: rgb(47, 139, 170);\n  border-width: 10px;\n  padding-left: 5px;\n  padding-right: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
