// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer-container {
    bottom: 0;
    width: 95%;
}

#footer-container > * {
    margin-bottom: 5px;
    margin-top: 0px;
}

#footer-container > *:hover {
    cursor: pointer;
}

#small-info-block {
    font-size: x-small;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["#footer-container {\n    bottom: 0;\n    width: 95%;\n}\n\n#footer-container > * {\n    margin-bottom: 5px;\n    margin-top: 0px;\n}\n\n#footer-container > *:hover {\n    cursor: pointer;\n}\n\n#small-info-block {\n    font-size: x-small;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
