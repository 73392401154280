import React from 'react';
import './core-platform.css'

const CorePlatform = () => {
    return (
        <div>
            <h2 className='generic-header'>Core Platform</h2>
            <p>Extract Experts leverages a powerful internal proprietary platform to deliver exceptional web scraping services. Key features include:</p>
            <ul>
                <li><u>Advanced Browser Automation</u>: Supports multiple browsers, virtual buffers/displays, and cookies to simulate complete user sessions.</li>
                <li><u>IP Flexibility</u>: Utilizes both residential and data center IPs for versatile scraping needs.</li>
                <li><u>Global Proxy Network</u>: Ensuring reliable and fast access to web data from around the world. Circumvent geo-fencing</li>
                <img src={require('../../assets_and_styling/images/internal_site/proxy_pools_example.png')} alt="proxy pools" className="internal-images" />
                <li><u>Native Jobs/Queue Management</u>: Abstracts job and queue management for seamless operation.</li>
                <li><u>Snapshot Management</u>: Simplifies running and retrying multiple inputs and tasks into "snapshots", streamlining the data collection process.</li>
                <img src={require('../../assets_and_styling/images/internal_site/snapshot_example.png')} alt="snapshot" className="internal-images" />
                <li><u>Unified Output</u>: Compiles data from various tasks into a single final output in JSON or CSV format.</li>
                <li><u>HTML Saving</u>: Facilitates easy root cause analysis (RCA) of bot interactions with sites and verification of data accuracy.</li>
                <img src={require('../../assets_and_styling/images/internal_site/task_example.png')} alt="tasks" className="internal-images" />
                <li><u>Customizable Retry Settings</u>: Built-in features to optimize and control retry attempts for enhanced data reliability.</li>
                <li><u>Custom Bot Framework</u>: Allows for rapid development of new extractors using shared logic and a command-line interface (CLI) tool for efficient management.</li>
                <li><u>Built-in Scheduling</u>: Offers flexible scheduling options to automate scraping tasks.</li>
            </ul>
            <p>With Extract Experts, you can trust in a sophisticated, customizable, and reliable web scraping solution tailored to meet your unique data extraction requirements.</p>
        </div>
    )
}

export default CorePlatform