import React from "react";
import Root from "./components/Root";
import configureStore from "./store/store";
import './index.css'
import { createRoot } from 'react-dom/client';

document.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault()
})

document.addEventListener("DOMContentLoaded", () => {
  let store = configureStore({});

  const container = document.getElementById('root');
  const root = createRoot(container); //

  root.render(<Root store={store} />);
});
