import React from 'react';
import './about-us.css'
import { Link } from "react-router-dom";


export const DescriptionText = () => {
    return (
        <div>
            Extract Experts is a boutique web-scraping company that partners with small to mid size companies to meet their web-scraping needs. Our team of experts owns the end to end process, from initial site scoping, down to extractor building, and continuous data delivery. Read more about our
            <Link to="/core-platform" className='general-link-text' > Core Platform </Link>
            that enables our data extraction processes.
            <br />
            <br />
            Our expertise lies in overcoming common obstacles such as blocking, CAPTCHAs, and other barriers that hinder effective data extraction. Leveraging our proprietary internal platform for bot orchestration, we efficiently manage large-scale web scraping operations. Our platform utilizes a global proxy network and custom frameworks to ensure seamless bot performance, delivering comprehensive results in an easy-to-read format.
        </div>
    )
}

export const AboutUs = () => {
    return (
        <section className='about-us'>
            <h2 className='generic-header'>About Us</h2>
            <h4>
                Welcome to Extract Experts!
            </h4>
            <DescriptionText />
            <br />
            Our services go beyond mere data extraction. We handle the entire process from scheduling to monitoring, offering complete observability and recording of all interactions with websites. Our detailed snapshots and HTML recordings ensure transparency and accuracy in the data we provide. Specializing in e-commerce and publicly available online data, we also cater to custom needs, developing automated solutions for a variety of use cases. At Extract Experts, we are committed to empowering businesses with precise, reliable, and scalable data extraction solutions tailored to their unique requirements.
            <br />
            <br />
            Common Web-Scraping Use Cases:
            <li>Travel Listings</li>
            <li>Item Pricing</li>
            <li>Competitor Data</li>
            <li>Search Results</li>
            <li>Page Rank</li>
            <li>Events and Tickets</li>
            <br />

            <h4>Founder - Adam Scott</h4>
            <img src={require('../../../assets_and_styling/images/founder.png')} alt="founder" id="founder-pic" />
            <br />
            <a href="https://www.linkedin.com/in/adam-scott-3bb693332/" target="_blank" rel="noreferrer noopener" id='outer-circle'>
                <img src={require('../../../assets_and_styling/images/linkedin_logo.png')} alt="linkedin link" id="linkedin-logo" />
            </a>
            <br />

            Adam Scott, the founder of Extract Experts, brings a wealth of experience and expertise to the company. With a distinguished career spanning across leading web scraping startups, Adam has successfully fulfilled complex data contracts for prominent e-commerce businesses. His professional journey includes pivotal roles at tech giants Apple and Meta, where he led impactful software engineering projects for Apple Maps and Core Ads. Adam’s deep understanding of data extraction, coupled with his innovative approach to solving web scraping challenges, has positioned Extract Experts as a premier B2B consulting solution. Under his leadership, the company is dedicated to delivering unparalleled data extraction services to small and mid-sized enterprises, empowering them to harness the full potential of online information.
            <br />
            Company
            <a href="https://www.linkedin.com/company/extract-experts/about/" className='general-link-text' target="_blank" rel="noreferrer noopener"  > LinkedIn</a>
        </section>
    )
}
