import React from 'react';
import { Link } from "react-router-dom";
import './navbar.css'

const NavBar = () => {
    return (
        <nav>
            <Link to='/' >
                <img src={require('../../assets_and_styling/images/logo_and_banners/logo_banner_2.png')} alt="robot logo" id='logo-banner' />
            </Link>
        </nav>
    )
}


export default NavBar;
