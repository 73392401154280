// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us img {
    border-radius: 50%;
}

#linkedin-logo {
    height: 45px;
    width: 45px;
}

#linkedin-logo:hover {
    opacity: 75%;
}

#founder-pic {
    height: 150px;
    width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/about-us/about-us.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".about-us img {\n    border-radius: 50%;\n}\n\n#linkedin-logo {\n    height: 45px;\n    width: 45px;\n}\n\n#linkedin-logo:hover {\n    opacity: 75%;\n}\n\n#founder-pic {\n    height: 150px;\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
