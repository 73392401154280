// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#site-title {
    cursor: pointer;
    border-radius: 5%;
    border-style: groove;
    border-color: rgb(70, 193, 234);
    border-width: 10px;
}

#site-title>h2 {
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
}

img#robot-logo {
    border-radius: 45%;
    height: 50px;
    width: 55px;
}

img#logo-banner {
    height: 60px;
    border-width: 5px;
    border-color: rgb(70, 193, 234);
    border-style: groove;
}

nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,+BAA+B;IAC/B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,+BAA+B;IAC/B,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":["#site-title {\n    cursor: pointer;\n    border-radius: 5%;\n    border-style: groove;\n    border-color: rgb(70, 193, 234);\n    border-width: 10px;\n}\n\n#site-title>h2 {\n    padding-left: 10px;\n    padding-right: 10px;\n    margin: 5px;\n}\n\nimg#robot-logo {\n    border-radius: 45%;\n    height: 50px;\n    width: 55px;\n}\n\nimg#logo-banner {\n    height: 60px;\n    border-width: 5px;\n    border-color: rgb(70, 193, 234);\n    border-style: groove;\n}\n\nnav {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    column-gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
