// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.internal-images {
    object-fit: scale-down;
    width: 75%;
}

@media (max-width: 600px) {
    .internal-images {
        object-fit: scale-down;
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/core_platform/core-platform.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI;QACI,sBAAsB;QACtB,WAAW;IACf;AACJ","sourcesContent":[".internal-images {\n    object-fit: scale-down;\n    width: 75%;\n}\n\n@media (max-width: 600px) {\n    .internal-images {\n        object-fit: scale-down;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
