import React from 'react';
import { Link } from "react-router-dom";
import './footer.css'

const Footer = () => {

    return (
        <footer id='footer-container'>
            <Link className='general-link-text' to='/about-us'>
                <h5>
                    About Us
                </h5>
            </Link>
            <Link className='general-link-text' to='/contact-us'>
                <h5>
                    Contact Us
                </h5>
            </Link>
            <Link className='general-link-text' to="/careers">
                <h5>
                    Careers
                </h5>
            </Link>
            <Link to="/core-platform" className='general-link-text' >
                <h5>Core Platform</h5>
            </Link>
            <h5 className='general-link-text'>
                <a href="https://www.linkedin.com/company/extract-experts/about/" target="_blank" rel="noreferrer noopener"  > LinkedIn</a>

            </h5>
            <div id='small-info-block'>
                © 2024 Scott Labs. All rights reserved&nbsp;
                <Link to='/privacy-policy' className='general-link-text'>
                    Privacy Policy
                </Link>
            </div>
        </footer>
    )
}


export default Footer;
