import React from 'react';
import './extract_experts_landing.css'
import ContactUs from '../footer/contact-us/ContactUs';
import { DescriptionText } from '../footer/about-us/AboutUs';
const ExtractExpertsLanding = () => {

  return (
    <div>
      <h2 className='generic-header'>
        Welcome to Extract Experts
      </h2>
      <div>
        <DescriptionText />
        <h3 className="generic-header">
          The Extract Experts Engagement Process
        </h3>
        <div className='side-by-side-flex'>
          <div className='step-container-flex'>
            <div className='sub-section'>
              <h4>
                <u>
                  Project Initiation
                </u>
              </h4>
              <p>
                We begin by thoroughly understanding your business and setting clear milestones and objectives that align with your strategic vision.
              </p>
            </div>
            <br />
            <div className='sub-section'>
              <h4><u>
                Customized Solution Design
              </u>
              </h4>
              <p>
                Next, we develop automated data extraction processes tailored to your specific requirements and provide an initial dataset for your evaluation before proceeding with full-scale implementation.
              </p>
            </div>
            <br />
            <div className='sub-section'>
              <h4>
                <u>
                  Full-Scale Project Execution
                </u>
              </h4>
              <p>
                Following your approval of the sample data, we will expand our operations to execute the full project, ensuring timely and high-quality data delivery.
              </p>
            </div>
            <br />
            <div className='sub-section'>
              <h4>
                <u>
                  Ongoing Maintenance and Support
                </u>
              </h4>
              <p>
                Since websites frequently update, data extraction can be challenging. Extract Experts' Support team is dedicated to providing assistance. Our developers, who understand the unique aspects of your project, will manage continuous maintenance to address site changes, following strict quality control procedures.
                Enjoy access to a streamlined ticketing system to troubleshoot issues as well as weekly/monthly business reviews.
              </p>
            </div>
          </div>
          <div className='right-side-container'>
            We handle E2E Extraction:
            <br />
            <img src={require('../../assets_and_styling/images/sample_website.png')} alt="sample site" className="sample-site" />
            Example website results:
            <br />
            <img src={require('../../assets_and_styling/images/search_page_example.png')} alt="sample webpage" className="sample-site" />
            Example data output:
            <br />
            <img src={require('../../assets_and_styling/images/sample_data_example.png')} alt="sample site" className="sample-site" />

          </div>
        </div>

      </div>
      <ContactUs />
    </div>
  );
};

export default ExtractExpertsLanding;
