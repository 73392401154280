// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-section {
    border-radius: 2%;
    border-style: groove;
    border-color: rgb(127, 216, 246);
    border-width: 5px;
}

.sub-section>* {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.step-container-flex {
    display: flexbox;
    width: 100%;
}

.side-by-side-flex {
    display: inline-flex;
}

/* Media query for small screens */
@media (max-width: 600px) {
    .side-by-side-flex {
        flex-direction: column; /* Stack items vertically */
    }
}

.sample-site {
    object-fit: scale-down;
    width: 100%;
}

.right-side-container {
    padding: 10px;
    /* margin: 10px; */
}
.right-side-container > * {
    margin-top: 10px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/landing_search/extract_experts_landing.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;;AAEA,kCAAkC;AAClC;IACI;QACI,sBAAsB,EAAE,2BAA2B;IACvD;AACJ;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".sub-section {\n    border-radius: 2%;\n    border-style: groove;\n    border-color: rgb(127, 216, 246);\n    border-width: 5px;\n}\n\n.sub-section>* {\n    padding-left: 10px;\n    padding-right: 10px;\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.step-container-flex {\n    display: flexbox;\n    width: 100%;\n}\n\n.side-by-side-flex {\n    display: inline-flex;\n}\n\n/* Media query for small screens */\n@media (max-width: 600px) {\n    .side-by-side-flex {\n        flex-direction: column; /* Stack items vertically */\n    }\n}\n\n.sample-site {\n    object-fit: scale-down;\n    width: 100%;\n}\n\n.right-side-container {\n    padding: 10px;\n    /* margin: 10px; */\n}\n.right-side-container > * {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
