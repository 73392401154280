import React from 'react';

const PrivacyPolicy = () => {
    return (
        <section>
            <h2 className='generic-header'>Privacy Policy</h2>
            <div>
                <h3>
                    Introduction
                </h3>

                Welcome to Extract Experts! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website or use our services.

                <h3>
                    Information We Collect
                </h3>

                Personal Information: Information you provide, such as name, email address, and phone number.
                Usage Data: Automatically collected information, including IP address, browser type, and browsing activity.
                Cookies: Used to enhance your experience; can be controlled through your browser settings.
                <h3>
                    How We Use Your Information
                </h3>

                To provide and maintain our services
                To improve and personalize your experience
                To communicate with you and provide customer support
                To analyze website usage and improve our offerings
                <h3>
                    Sharing Your Information
                </h3>

                We do not sell or trade your personal information. We may share it with trusted third parties for business operations or if required by law.
                <h3>
                    Data Security
                </h3>

                We implement various security measures to protect your personal information. However, no method of transmission over the internet is 100% secure.
                <h3>
                    Third-Party Links
                </h3>

                Our website may contain links to third-party websites. We are not responsible for their privacy practices or content.
                <h3>
                    Changes to This Privacy Policy
                </h3>

                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. Your continued use of our website constitutes acceptance of the updated policy.
            </div>
        </section>
    )
}


export default PrivacyPolicy;
